<template>
  <div>
    <van-pull-refresh v-model="isLoading" success-text="刷新成功" :disabled="refreshDisabled" @refresh="onRefresh" style="height: 100vh !important;
 	overflow: auto !important;">
      <div class='core_home' style="height: auto !important; ">
        <!-- 个人信息 -->
        <InformationCard :personalInfo="personalInfo"></InformationCard>
        <div class="content">
          <div class="substance">
            <!-- 轮播图 -->
            <swiper :swiperItem="swiperItem"></swiper>
            <!-- 分割线 -->
            <splitline></splitline>
            <!-- 金刚区 -->
            <banner></banner>
            <splitline></splitline>
            <!-- 检测分析 -->
            <test :testData="testData"></test>
            <splitline></splitline>
          </div>
          <!-- 每日关注 -->
          <div class="substance">
            <titlenav :text="'每日关注'"></titlenav>
          </div>
          <div class="marg_left">
            <!-- 每日关注 -->
            <slide :type="'top'" :slideItem="slideItem"></slide>
          </div>
          <!-- 我的健康 -->
          <div class="substance">
            <splitline></splitline>
            <div @click="getIsClickMore">
              <titlenav :text="'我的健康'"></titlenav>
            </div>
          </div>
          <div class="marg_left">
            <!-- 我的健康 -->
            <slide :type="'organ'" :slideItem17="slideItem17" :slideItem="slideItem2"></slide>
          </div>

          <!-- 我的疗愈计划 -->
          <div class="substance">
            <splitline></splitline>
            <div @click="getMyPlan">
              <titlenav :text="'我的疗愈计划'"></titlenav>
            </div>
          </div>
          <div class="marg_left">
            <!-- 我的疗愈计划 -->
            <slide :type="'titlebutBig'" :slideItem="slideItem3"></slide>
          </div>
          <!-- 即时疗愈 -->
          <div class="substance">
            <splitline></splitline>
            <titlenav :text="'即时疗愈'"></titlenav>
          </div>
          <div class="marg_left">
            <!-- 即时疗愈 -->
            <slide :type="'onlyleft'" :slideItem="slideItem4"></slide>
          </div>
          <!-- 情绪随时间 -->
          <div class="substance">
            <splitline></splitline>
            <titlenav :text="'情绪随时间'"></titlenav>
          </div>
          <div class="marg_left">
            <!-- 情绪随时间 -->
            <slide :type="'emotion'" :slideItem="slideItem5"></slide>
          </div>

          <!-- 疗愈世界 -->
          <div class="substance">
            <splitline></splitline>
            <titlenav :text="'疗愈世界'"></titlenav>
          </div>
          <div class="marg_left">
            <!-- 疗愈世界 -->
            <slide :type="'leftright'" :slideItem="slideItem6"></slide>
          </div>
          <!-- 健康锦囊 -->
          <div class="substance">
            <splitline></splitline>
            <swiper :swiperItem="swiperItem2"></swiper>
            <splitline></splitline>
            <!-- 健康锦囊 -->
            <titlenav :text="'健康锦囊'"></titlenav>
            <silkBag :silkImg="silkImg"></silkBag>

            <splitline></splitline>
            <titlenav :text="'产品推荐'"></titlenav>
            <!-- 商品列表 -->
            <goodsList></goodsList>
          </div>
          <!-- <custom-tab-bar></custom-tab-bar>> -->
        </div>
        <!-- 二级页跳转 -->
        <router-view />
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import { IsClickMore, GetUserInfo, IsQuestionByUserId } from '@/api/index'
import { mapGetters } from 'vuex'
import swiper from '@/components/swiper/index'
import banner from '@/components/banner/index'
import test from '@/components/testing/index'
import slide from '@/components/slide/index'
import goodsList from '@/components/goodsList/index'
import InformationCard from '@/components/InformationCard/index'
import silkBag from '@/components/silkBag/index'
import data from '@/components/data'
import wx from '@/util/jweixin1.3.2'
export default {
  components: {
    InformationCard,
    swiper,
    banner,
    test,
    slide,
    goodsList,
    silkBag
  },
  data () {
    return {
      count: 0,
      isLoading: false,
      personalInfo: '',
      swiperItem: [
        {
          url: `${this.$imgDomain}/www/HealthingCore/swiper1.png`
        },
        {
          url: `${this.$imgDomain}/www/HealthingCore/swiper1.png`
        },
        {
          url: `${this.$imgDomain}/www/HealthingCore/swiper1.png`
        }
      ],
      testData: {
        imgUrl: `${this.$imgDomain}/www/HealthingCore/test.png`,
        textColor: '#FFFFFF',
        textArr: [
          {
            text: '智能检测',
            lineColor: '#FF9580'
          },
          {
            text: '情绪与健康分析',
            lineColor: '#00CCCC',
            miniPage: '/pages/photograph/index',
            type: 9
          }
        ]
      },
      swiperItem2: [
        {
          url: `${this.$imgDomain}/www/HealthingCore/swiper.png`
        },
        {
          url: `${this.$imgDomain}/www/HealthingCore/swiper.png`
        },
        {
          url: `${this.$imgDomain}/www/HealthingCore/swiper.png`
        }
      ],
      silkImg: `${this.$imgDomain}/www/HealthingCore/silkbag.png`,
      slideItem: data.slideItem,
      slideItem2: data.slideItem2,
      slideItem3: data.slideItem3,
      slideItem4: data.slideItem4,
      slideItem5: data.slideItem5,
      slideItem6: data.slideItem6,
      slideItem7: data.slideItem7,
      slideItem17: data.slideItem17,
      refreshDisabled: false
    }
  },
  created () {
    const openid = this.$query('openid')
    const token = this.$query('token')
    const userid = this.$query('userid')
    token && this.$store.commit('SET_TOKEN', token)
    openid && this.$store.commit('SET_OPENID', openid)
    userid && this.$store.commit('SET_USERID', userid)
  },
  mounted () {
    var that = this
    that.getUserInfo()
  },
  methods: {
    // 下拉刷新
    onRefresh () {
      setTimeout(() => {
        wx.miniProgram.redirectTo({
          url: 'pages/initialize/coreHome?refresh=1'
        })
        wx.miniProgram.postMessage({
          data: {
            data: '0'
          }
        })
        this.isLoading = false
      }, 1000)
    },
    // 获取用户信息
    getUserInfo () {
      const userid = this.userid ? this.userid : this.$query('userid')
      this.$toast.loading({
        message: '获取信息中'
      })
      GetUserInfo({ userId: this.userid }).then(res => {
        const { data } = res
        if (res.code == 0) {
          if (res.data.userVipEndTime == '') {
            this.$router.push('/updateUserInfo')
          } else {
            this.userInfo = data
            window.localStorage.setItem(
              'userInfo', JSON.stringify(data)
            )
            this.personalInfo = JSON.parse(window.localStorage.getItem('userInfo') || '[]')
            this.getYearMonth(this.personalInfo.userVipEndTime)
          }
        }
      })
    },
    getIsQuestionByUserId () {
      IsQuestionByUserId({ userId: this.userid }).then(res => {
        if (res.code == 0) {
          console.log(res)
          if (res.data == true) {
            this.$router.push('/myhealthSLOne')
          } else {
            this.$router.push('/myhealthSL')
          }
        }
      })
    },
    // 判断用户能否点击更多
    getIsClickMore () {
      IsClickMore({ userId: this.userid }).then(res => {
        if (res.code == 0) {
          this.getIsQuestionByUserId()
        } else {
          this.$toast.fail('暂无报告数据')
        }
      })
    },
    getYearMonth (date) {
      // 将日期以空格隔开，即['2020-06-13', '17:10:09']
      date = (date + '').split(/[ ]+/)
      const result = []
      const reg = /^(\d{4})-(\d{1,2})-(\d{1,2})$/
      // 用截取出来的年月日进行正则表达式匹配
      reg.exec(date[0])
      result.push(RegExp.$1)	// 获取匹配到的第一个子匹配，即‘2020’
      result.push(RegExp.$2)
      result.push(RegExp.$3)
      console.log(result, '---')
      this.personalInfo.userVipEndTime = result
    },
    getMyPlan () {
      IsClickMore({ userId: this.userid }).then(res => {
        if (res.code == 0) {
          this.$router.push('/MyHealingPlan')
        } else {
          // return
          this.$toast.fail('暂无报告数据')
        }
      })
    }

  },
  watch: {

  },

  computed: {
    ...mapGetters([
      'userid'
    ])
  }
}
</script>
<style lang='scss' scoped>
.core_home {
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  overflow-y: scroll;

  .content {
    width: 100%;
    padding-bottom: 60px;
    border-radius: 30px 30px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f2f2f2;
    border-top: 1px solid #fff;
    box-shadow: 0 -10px 20px #e2e2e2;

    .substance {
      width: 100%;
      padding: 0 25px 0 25px;
    }

    .marg_left {
      width: 100%;
      padding-left: 25px;
    }

    .circular {
      display: flex;
      justify-content: space-between;

      .cir_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 32%;

        .cir_img {
          width: 100%;
          border-radius: 50%;
          overflow: hidden;
        }

        .title {
          width: 100%;
          color: #898989;
          margin-top: 8px;
          line-height: .2rem;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .cir_item:nth-child(2) .title {
        border-right: 2px solid #898989;
        border-left: 2px solid #898989;

      }
    }
  }
}
</style>
